import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID_NUMBER,
    type: 'text',
  },
  {
    id: 'taxIdNumber',
    key: 'taxIdNumber',
    name: dictionary().TAX_ID_NUMBER,
    type: 'text',
  },
  {
    id: 'tax_ids_corporates',
    key: 'tax_ids_corporates',
    name: dictionary().CUSTOMER,
    type: 'text',
  },
]

const TaxIds: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().TAX_IDS}
      tags={[]}
      hasSearch={false}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['tax-id']}
      countArrayPath={['count']}
      restRoute={'/tax-id'}
      cashingKey={CASHING_KEY.GET_TAX_IDS}
      enableIdRedirect={true}
    />
  )
}

export default TaxIds
