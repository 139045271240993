import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Button, FormControl, FormErrorMessage, FormLabel, GridItem, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import dictionary from '../../dictionary'
import Reqiured from './reqiured'

interface Props {
  register: any
  errors: any
  watch: any
  col: any
  error?: any
}

const PasswordAndConfirmationGrid: React.FC<Props> = ({ register, errors, watch, col, error }) => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickPassword = () => setShowPassword(!showPassword)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)
  const handleClickPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation)

  const passwordValue = useRef({})
  passwordValue.current = watch('password', '')

  return (
    <>
      <GridItem colSpan={col}>
        <FormControl
          id={dictionary().PASSWORD}
          isInvalid={!!errors.password || (error as any)?.data?.message[0] === 'password must be shorter than or equal to 20 characters'}
        >
          <FormLabel>
            {dictionary().PASSWORD} <Reqiured />{' '}
          </FormLabel>

          <InputGroup size='md'>
            <Input
              data-test={dictionary().PASSWORD}
              pr='1rem'
              {...register('password', {
                required: dictionary().REQUIRED,
                pattern: {
                  value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                  message: dictionary().WEAK_PASSWORD,
                },
              })}
              type={showPassword ? 'text' : 'password'}
            />

            <InputLeftElement display='flex' justifyContent='center' alignItems='center' cursor='pointer' paddingBottom={'0.3rem'}>
              <span onClick={handleClickPassword}>{showPassword ? <ViewIcon /> : <ViewOffIcon />}</span>
            </InputLeftElement>
          </InputGroup>
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          <FormErrorMessage>
            {(error as any)?.data?.message[0] === 'password must be shorter than or equal to 20 characters' && dictionary().INVALID_PASSWORD_LENGTH}
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={col}>
        <FormControl id={dictionary().PASSWORD_CONFIRMATION} isInvalid={!!errors.confirmedPass}>
          <FormLabel>
            {dictionary().PASSWORD_CONFIRMATION} <Reqiured />
          </FormLabel>
          <InputGroup size='md'>
            <Input
              data-test={dictionary().PASSWORD_CONFIRMATION}
              required
              id='confirmedPass'
              pr='1rem'
              type={showPasswordConfirmation ? 'text' : 'password'}
              {...register('confirmedPass', {
                required: dictionary().REQUIRED,
                validate: (value: any) => {
                  if (value !== passwordValue.current) return dictionary().PASSWORDS_NOT_MATCHED
                },
              })}
            />
            <InputLeftElement display='flex' justifyContent='center' alignItems='center' cursor='pointer' paddingBottom={'0.3rem'}>
              <span onClick={handleClickPasswordConfirmation}>{showPasswordConfirmation ? <ViewIcon /> : <ViewOffIcon />}</span>
            </InputLeftElement>
          </InputGroup>
          <FormErrorMessage>{errors.confirmedPass?.message}</FormErrorMessage>
        </FormControl>
      </GridItem>
    </>
  )
}

export default PasswordAndConfirmationGrid
