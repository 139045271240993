import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Text,
} from '@chakra-ui/react'
import { FC, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import filterIconSrc from '../../assets/filter.svg'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import { CustomSelect } from '../../components/core/CustomSelectInput'
import dictionary from '../../dictionary'
import useQueryParams from '../../hooks/useQueryParams '

type CarServicesFilterModelProps = any

const CarServicesFilterModel: FC<CarServicesFilterModelProps> = ({ isOpen, onOpen, onClose }: any) => {
  const { add, remove } = useQueryParams()

  const servicesType = [
    { label: dictionary().CAR_WASH, value: 'CAR_WASH' },
    { label: dictionary().OIL_CHANGE, value: 'OIL_CHANGE' },
  ]

  const searchRef = useRef<HTMLButtonElement>(null)
  const { control, watch, setValue, getValues } = useForm({ mode: 'onChange' })

  const fieldValues = {
    corporate_ids: watch('corporate_ids', []),
    driver_id: watch('driver_id', []),
    driver_code: watch('driver_code', []),
    vehicle_code: watch('vehicle_code', []),
    plate_number: watch('plate_number', []),
    station_ids: watch('station_ids', []),
    service_types: watch('service_types', []),
  }

  useEffect(() => {
    setValue('corporate_ids', [])
    setValue('driver_id', [])
    setValue('station_ids', [])
    setValue('service_types', [])
    setValue('driver_code', [])
    setValue('vehicle_code', [])
    setValue('plate_number', [])
  }, [])

  const clearAll = () => {
    remove('corporate_ids')
    remove('station_ids')
    remove('service_types')
    remove('driver_id')
    remove('vehicle_id')

    Object.keys(fieldValues).forEach((field) => setValue(field, []))

    onClose()
  }

  const submit = async () => {
    remove('corporate_ids')
    remove('station_ids')
    remove('service_types')
    remove('driver_id')
    remove('vehicle_id')

    const driverId = [...getValues('driver_id'), ...getValues('driver_code')]
    const uniqueDriversId = [...new Set(driverId)]
    const vehicleId = [...getValues('vehicle_code'), ...getValues('plate_number')]
    const uniqueVehiclesId = [...new Set(vehicleId)]

    getValues('corporate_ids')?.length > 0 && add('corporate_ids', getValues('corporate_ids'))
    getValues('station_ids')?.length > 0 && add('station_ids', getValues('station_ids'))
    getValues('service_types')?.length > 0 && add('service_types', getValues('service_types'))
    uniqueDriversId?.length > 0 && add('driver_id', uniqueDriversId)
    uniqueVehiclesId?.length > 0 && add('vehicle_id', uniqueVehiclesId)
    onClose()
  }

  return (
    <>
      <Box position='relative' display='inline-block' sx={{ mr: '10px' }}>
        <Button onClick={onOpen} variant='outline' style={{ backgroundColor: '#7165E3' }} _focus={{ boxShadow: 'none' }}>
          <Image src={filterIconSrc} width={25} height={30} />
        </Button>
        {/* Red dot indicator */}
        {Object.values(fieldValues).some((value) => value?.length > 0) && (
          <Box position='absolute' top='-1' right='-1' width='15px' height='15px' borderRadius='50%' backgroundColor='red'></Box>
        )}
      </Box>
      <AlertDialog motionPreset='slideInBottom' leastDestructiveRef={searchRef} onClose={submit} isOpen={isOpen} isCentered autoFocus colorScheme={'purple'}>
        <AlertDialogOverlay />
        <AlertDialogContent dir='rtl' maxW='900px'>
          <AlertDialogHeader>{dictionary().ADVANCED_FILTERATION}</AlertDialogHeader>
          <AlertDialogBody>
            <Grid templateColumns='repeat(12, 1fr)' gap={4} alignItems={'center'}>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().CHOOSE_CORPORATE} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().CHOOSE_CORPORATE}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.corporate_ids?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`corporate_ids`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        onChange={onChange}
                        value={value}
                        searchRoute='/corporates'
                        singleRoute='/corporates'
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.name}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                        multiple={true}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().CHOOSE_STATION} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().CHOOSE_STATION}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.station_ids?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`station_ids`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        onChange={onChange}
                        value={value}
                        searchRoute='/stations'
                        singleRoute='/stations'
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.name?.ar}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                        multiple={true}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().SERVICE_TYPE} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().SERVICE_TYPE}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.service_types?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`service_types`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomSelect placeholder={'اختر الخدمة'} options={servicesType} value={value} onChange={onChange} />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().DRIVER_NAME} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().DRIVER_NAME}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.driver_id?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`driver_id`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        onChange={onChange}
                        value={value}
                        searchRoute='/drivers'
                        singleRoute='/drivers'
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.name}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                        multiple={true}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().DRIVER_CODE} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().DRIVER_CODE}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.driver_code?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`driver_code`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        onChange={onChange}
                        value={value}
                        searchRoute='/drivers'
                        singleRoute='/drivers'
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.code}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                        multiple={true}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().VEHICLE_CODE} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().VEHICLE_CODE}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.vehicle_code?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`vehicle_code`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        onChange={onChange}
                        value={value}
                        searchRoute='/vehicles'
                        singleRoute='/vehicles'
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.code}
                        displayNamePathFormatter={(option) => option?.vehicle}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                        multiple={true}
                        query={{ app: 'dashboard' }}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={[12, 6]}>
                <FormControl id={dictionary().NUMBER_PLATE} dir='rtl'>
                  <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
                    {dictionary().NUMBER_PLATE}{' '}
                    <Text
                      style={{
                        width: '20px',
                        height: '20px',
                        color: '',
                        borderRadius: '50%',
                        backgroundColor: '#EDF2F7',
                        textAlign: 'center',
                        fontSize: '12px',
                      }}
                    >
                      {fieldValues.plate_number?.length || 0}
                    </Text>
                  </FormLabel>
                  <Controller
                    name={`plate_number`}
                    rules={{ required: false }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteInput
                        onChange={onChange}
                        value={value}
                        searchRoute='/vehicles'
                        singleRoute='/vehicles'
                        valueFormatter={(option) => option?.id}
                        displayFormatter={(option) => option?.number_plate}
                        displayNamePathFormatter={(option) => option?.vehicle}
                        boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
                        multiple={true}
                        query={{ app: 'dashboard' }}
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'outline'} onClick={onClose}>
              {dictionary().CANCEL}
            </Button>
            <Button mr='3' style={{ backgroundColor: '#E0E0E0' }} variant={'solid'} onClick={() => clearAll()}>
              {dictionary().CLEAR_ALL}
            </Button>
            <Button ref={searchRef} mr='3' colorScheme='blue' variant={'solid'} onClick={() => submit()}>
              {dictionary().SEARCH}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default CarServicesFilterModel
