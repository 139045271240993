import { Box, Checkbox, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import dictionary from '../../../dictionary'
import Card from '../Card'
import Reqiured from '../reqiured'
import FormEdit from './FormEdit'
import { Detail } from './VersionDetailsData'

interface Props {
  register: any
  errors: any
  control: any
  watch: any
  setValue: any
  bundles: any
  getValues: any
  disabled?: boolean
}

const AITrial: FC<Props> = ({ register, errors, control, watch, setValue, bundles, getValues, disabled }) => {
  const ai_trial_enabled = watch('ai_trial_enable')
  const ai_trial_number_days = watch('ai_trial_number_days')
  const bundle_type = watch('bundle_type')

  useEffect(() => {
    if (!ai_trial_enabled) {
      setValue('ai_trial_number_days', 0)
    } else {
      // setValue('ai_trial_number_days', 1)
    }
  }, [ai_trial_enabled])

  return (
    <Card p='5'>
      <Grid templateColumns='repeat(12, 1fr)' gap={4}>
        <GridItem colSpan={[12]}>
          <Text
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'right',
            }}
            mb={5}
            pb={5}
            pr={2}
            borderBottom='1px solid rgba(226, 232, 240, 1)'
          >
            {dictionary().AI_TRIAL}
          </Text>
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <FormControl id={'ai_trial_enable'} w='50%' isInvalid={!!errors?.["'ai_trial_enable'"]} data-test='ai_trial_enable' isDisabled={disabled}>
            <Controller
              name={'ai_trial_enable'}
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  onChange={() => setValue('ai_trial_enable', !ai_trial_enabled)}
                  data-test={'ai_trial_enable'}
                  isChecked={ai_trial_enabled}
                  textTransform='capitalize'
                >
                  {dictionary().AI_TRIAL_ACTIVATE}
                </Checkbox>
              )}
              rules={{
                required: false,
              }}
            />
            <FormErrorMessage>{errors['ai_trial_enable']?.message}</FormErrorMessage>
          </FormControl>
          {ai_trial_enabled === true && (
            <FormControl id={'ai_trial_number_days'} w='50%' display='inline-block' isInvalid={!!errors['ai_trial_number_days']} marginTop={5}>
              <FormLabel>مدة التجربة (عدد الأيام)</FormLabel>
              <Input
                data-test='ai_trial_number_days'
                style={{
                  fontFamily: 'Noto Kufi Arabic',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  textAlign: 'right',
                }}
                disabled={disabled}
                type={'number'}
                {...register('ai_trial_number_days', {
                  required: 'هذا الحقل مطلوب و يجب ان يحتوي على ارقام فقط',
                  min: {
                    value: 1,
                    message: 'أقل قيمة 1',
                  },
                  max: {
                    value: 730,
                    message: 'أكبر قيمة 730',
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'هذا الحقل يجب ان يحتوي على ارقام فقط',
                  },
                })}
                placeholder='عدد أيام الفترة التجريبية'
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    event.preventDefault() // Stop the default action of the event
                  }
                }}
              />
              <FormErrorMessage>{errors['ai_trial_number_days']?.message}</FormErrorMessage>
            </FormControl>
          )}
        </GridItem>
        {/* {type && getChargingProfileTypeInputs()} */}
      </Grid>
    </Card>
  )
}

export default AITrial

// export const DurationRadioOptions: FC<InputProps> = ({ register, errors, control, watch }) => {
//   const type = watch('durationType')
//   const DurationOptions = [
//     {
//       label: dictionary().FIFTEEN_DAYS,
//       value: '15',
//     },
//     {
//       label: dictionary().THIRTY_DAYS,
//       value: '30',
//     },
//   ]
//   const options = [
//     {
//       type: 'radio',
//       register: register?.('durationType', {
//         required: dictionary().REQUIRED,
//       }),
//       options: [
//         {
//           label: dictionary().PERIOD,
//           value: 'FIXED_DURATION',
//         },
//         {
//           label: dictionary().SELECT_PERIOD,
//           value: 'CUSTOM_DURATION',
//         },
//       ],
//     },
//   ]

//   const getInputsType = () => {
//     const selectDuration: Detail = {
//       type: 'select',
//       register: register?.('trial_duration_in_days', {
//         required: dictionary().REQUIRED,
//       }),
//       options: DurationOptions,
//       name: '',
//       value: '',
//     }

//     const customDuration = {
//       type: 'number',
//       register: register?.('trial_duration_in_days', {
//         required: dictionary().REQUIRED,
//         min: {
//           value: 0,
//           message: dictionary().NO_NEGATIVE_NUMBER,
//         },
//       }),
//       name: '',
//       value: '',
//     }

//     switch (type) {
//       case 'FIXED_DURATION':
//         return (
//           <Box w={'20%'} mb={2}>
//             <Text
//               style={{
//                 fontFamily: 'Noto Kufi Arabic',
//                 fontSize: '14px',
//                 fontWeight: 500,
//                 lineHeight: '20px',
//                 textAlign: 'left',
//               }}
//               color='#808080'
//               mb={2}
//               pt={'20px'}
//             >
//               <FormEdit detail={selectDuration} control={control} errors={errors} />
//             </Text>
//           </Box>
//         )
//       case 'CUSTOM_DURATION':
//         return (
//           <Box w={'20%'} mb={2}>
//             <Text
//               style={{
//                 fontFamily: 'Noto Kufi Arabic',
//                 fontSize: '14px',
//                 fontWeight: 500,
//                 lineHeight: '20px',
//                 textAlign: 'left',
//               }}
//               color='#808080'
//               mb={2}
//               pt={'20px'}
//             >
//               <FormEdit detail={customDuration} control={control} errors={errors} />
//             </Text>
//           </Box>
//         )
//       default:
//         return null
//     }
//   }

//   return (
//     <>
//       {options.map((item: any, index: number) => (
//         <GridItem colSpan={[8, 4, 4]} key={index}>
//           <Text
//             color='#808080'
//             style={{
//               fontFamily: 'Noto Kufi Arabic',
//               fontSize: '14px',
//               fontWeight: 500,
//               lineHeight: '20px',
//               textAlign: 'left',
//             }}
//             pt={'20px'}
//           >
//             <FormEdit key={index} detail={item} control={control} errors={errors} />
//           </Text>
//         </GridItem>
//       ))}
//       <GridItem colSpan={[12]}>{getInputsType()}</GridItem>
//     </>
//   )
// }

// const ChargingTypeInputs: FC<InputProps> = ({ register, errors, control }) => {
//   const options = [
//     {
//       name: dictionary().END_DATE,
//       type: 'date',
//       register: register?.('expires_at', {
//         required: dictionary().REQUIRED,
//       }),
//     },
//   ]

//   return (
//     <>
//       {options.map((item: any, index: number) => (
//         <GridItem colSpan={[8, 4, 4]} key={index} mb={2}>
//           <Text fontSize='19px' fontWeight='600'>
//             <Text color='#808080' mb={2} fontSize='16px'>
//               {item.name} <Reqiured />
//             </Text>
//             <FormEdit key={index} detail={item} control={control} errors={errors} />
//           </Text>
//         </GridItem>
//       ))}
//     </>
//   )
// }
