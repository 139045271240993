import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import Card from '../../components/core/Card'
import dictionary from '../../dictionary'

const TaxIdMainInfo = ({ taxIdHeaders, taxIdData, isLoading }: any) => {
  const getTaxData = (data: any, key: string) => {
    switch (key) {
      case 'tax_ids_corporates':
        let customersNames: any = []
        if (data?.corporates?.length > 0) {
          customersNames = data?.corporates.map((corporate: any) => {
            return corporate?.customer?.name
          })

          customersNames = new Set(customersNames)
          customersNames = Array.from(customersNames)

          return (
            <Grid templateColumns='repeat(12, 1fr)' gap={4}>
              {customersNames.map((customerName: any) => (
                <GridItem key={customerName} colSpan={[6]}>
                  <Text mt={0} pb='0' fontSize='19px' fontWeight={600} mx={2}>
                    {customerName}
                  </Text>
                </GridItem>
              ))}
            </Grid>
          )
        }
        return '-'
      default:
        return data[key]
    }
  }

  return (
    <Card h='100%' p={3}>
      <Text m={3} fontSize='20px' fontWeight={700} p={[5, 2, 2, 2]}>
        {dictionary().PERSONAL_INFO}
      </Text>
      <hr />
      <Grid templateColumns='repeat(12, 1fr)' gap={4} px={8}>
        {taxIdHeaders.map((header: any) => (
          <GridItem key={header.key} colSpan={header.key == 'tax_ids_corporates' ? 12 : [6]}>
            <Box w='100%'>
              <Text mt={2} fontSize='16px' color='grey'>
                {header.name}
              </Text>
              <Text mt={0} pb='0' fontSize='19px' fontWeight={600}>
                {getTaxData(taxIdData, header.key)}
              </Text>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Card>
  )
}

export default TaxIdMainInfo
