import { FormControl, FormLabel, Text } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import dictionary from '../../dictionary'
import useQueryParams from '../../hooks/useQueryParams '
import AutoCompleteInput from './AutoCompleteInput'

const MultipeCustomerDropdown: FC = () => {
  const { add, remove } = useQueryParams()
  const {
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm({ mode: 'onChange' })

  const customerIds = watch('customerIds')

  const fieldValues = {
    customerIds: watch('customerIds', null),
  }

  useEffect(() => {
    remove('customerIds')
    getValues('customerIds')?.length > 0 && add('customerIds', getValues('customerIds'))
  }, [customerIds])

  return (
    <FormControl id={dictionary().CUSTOMERS} w='100%' dir='rtl' marginTop={5}>
      <FormLabel style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '5px' }}>
        {dictionary().CUSTOMERS}
        <Text
          style={{
            width: '20px',
            height: '20px',
            color: '',
            borderRadius: '50%',
            backgroundColor: '#EDF2F7',
            textAlign: 'center',
            fontSize: '12px',
          }}
        >
          {fieldValues.customerIds?.length || 0}
        </Text>
      </FormLabel>
      <Controller
        name={`customerIds`}
        rules={{ required: false }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <AutoCompleteInput
            isInvalid={!!errors?.second_ref_id}
            onChange={onChange}
            value={value}
            multiple={true}
            searchRoute='/customers'
            singleRoute='/customers'
            valueFormatter={(option) => option?.id}
            displayFormatter={(option) => option?.name}
            boxProps={{ maxWidth: '550px', maxHeight: '40px', overflowY: 'auto' }}
            dataTest={dictionary().CUSTOMERS}
          />
        )}
      />
    </FormControl>
  )
}

export default MultipeCustomerDropdown
