import React, { useState } from 'react'
import { BsFillPeopleFill } from 'react-icons/bs'
import { HiReceiptTax } from 'react-icons/hi'
import { IoReceipt } from 'react-icons/io5'
import dictionary from '../../dictionary'
import NavItem from '../core/NavItem'

type Props = {
  type: string
}

const userDefinion = {
  icon: IoReceipt,
  title: dictionary().USER_DEFINTION,
  subItems: [
    {
      icon: BsFillPeopleFill,
      title: dictionary().CORPORATES,
      to: '/corporates',
    },
    {
      icon: BsFillPeopleFill,
      title: dictionary().CORPORATE_GROUPS,
      to: '/corporate-groups',
    },
    {
      icon: HiReceiptTax,
      title: dictionary().TAX_IDS,
      to: '/tax-ids',
    },
  ],
}

const showItems = [{ ...userDefinion }]

const AccountManagerSideBar: React.FC<Props> = ({ type }) => {
  const [selected, setSelected] = useState('')

  return (
    <>
      {showItems.map((item: any) => (
        <NavItem
          type={type}
          selected={selected}
          setSelected={setSelected}
          key={item.title}
          icon={item.icon}
          subItems={item.subItems}
          to={item.to}
          title={item.title}
        />
      ))}
    </>
  )
}

export default AccountManagerSideBar
