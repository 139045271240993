import { Box, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import Card from './Card'

const StationServices: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [stationData, setStationData] = React.useState<any>([])
  const { mutate } = useMutation(() => fetch('GET', `/stations/${id}`), {
    onSuccess: (data) => {
      setStationData(data)
    },
  })

  useEffect(() => {
    mutate()
  }, [])

  const stationServicesHeaders = [
    {
      name: dictionary().CAR_WASH,
      value: stationData?.enable_car_wash ? dictionary().ACTIVE : dictionary().INACTIVE,
    },
    {
      name: dictionary().OIL_CHANGE,
      value: stationData?.enable_oil_change ? dictionary().ACTIVE : dictionary().INACTIVE,
    },
  ]

  return (
    <Card h='100%' p={3}>
      <Text m={3} fontSize='20px' fontWeight={700} p={[5, 2, 2, 2]}>
        {dictionary().SERVICES}
      </Text>
      <hr />
      <Grid templateColumns='repeat(6, 1fr)' mt={5}>
        {stationServicesHeaders.map((header, index) => (
          <GridItem colSpan={3} key={index}>
            <Stack m={2} direction='row'>
              <Box w='100%' pr='0'>
                <Text fontSize='l' color='grey'>
                  {header.name}
                </Text>
                <Text mt={0} fontSize='xl'>
                  {header.value}
                </Text>
              </Box>
            </Stack>
          </GridItem>
        ))}
      </Grid>
    </Card>
  )
}

export default StationServices
