import React, { useRef } from 'react'
import { FormControl, FormLabel, Input, Flex, FormErrorMessage, InputLeftElement, InputGroup, Select, Textarea, Hide } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { parse } from 'query-string'
import { format } from 'date-fns'
import dictionary from '../../dictionary'
import useAlert from '../../helpers/useAlert'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Card from '../../components/core/Card'
import SaveButton from '../../components/core/SaveButton'
import Reqiured from '../../components/core/reqiured'
import fetch from '../../helpers/fetch'
import ConfirmationModel from '../../components/core/ConfirmationModel'

type addPaymentsVariables = {
  amount: number
  date: string
  entity_type: number
  id: number
  image: any
}

const AddDeposits: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)

  const { push } = useHistory()
  const query = parse(location.search, { parseBooleans: true })

  const { id } = useParams<{ id: string }>()
  const { onSuccess, onError } = useAlert()
  const { pathname } = useLocation()
  let restApi = ''
  if (pathname.includes('corporates')) {
    restApi = `/ledger-transactions/deposit`
  } else {
    restApi = `/ledger-transactions/withdraw`
  }

  const entityData = useQuery([query, 'corporates'], () => fetch('GET', `${pathname.includes('corporates') ? '/corporates' : '/station-providers'}/${id}`))
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', restApi, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`${pathname.includes('corporates') ? '/corporates' : '/station-providers'}/${id}`)
    },
    onError,
  })

  const addPayments = async (formData: any) => {
    formData.entity_id = +id
    formData.entity_type = pathname.includes('corporates') ? 'CORPORATE' : 'STATION_PROVIDER'
    //
    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Hide below='769px'>
          <Header title='' />
        </Hide>
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(addPayments as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            {' '}
            <FormControl
              id={dictionary().ADD_DEPSOIT}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.amount || error?.data?.message[0] === 'amount should not be empty'}
            >
              <FormLabel>
                <>
                  {dictionary().ADD_DEPSOIT}
                  <Reqiured />
                </>
              </FormLabel>

              <InputGroup>
                <InputLeftElement height='100%' pointerEvents='none'>
                  {dictionary().PRICING_CURRENCY}
                </InputLeftElement>
                <Input
                  data-test='amount'
                  type='number'
                  {...register('amount', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>

              <FormErrorMessage>{errors?.amount?.message}</FormErrorMessage>
              <FormErrorMessage>
                {error?.data?.message === 'balance is less than the transaction amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
              </FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().DATE} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors?.date}>
              <FormLabel>
                {dictionary().DATE}
                <Reqiured />
              </FormLabel>

              <InputGroup>
                <Input
                  defaultValue={format(new Date(), 'yyyy-MM-dd')}
                  type='date'
                  {...register('date', {
                    required: dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().PAYMENT_METHOD}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.payment_method}
            >
              <FormLabel>
                {dictionary().PAYMENT_METHOD}
                <Reqiured />
              </FormLabel>
              <InputGroup>
                <Select
                  data-test='payment_method'
                  dir='ltr'
                  placeholder={dictionary().PAYMENT_METHOD}
                  textAlign='right'
                  {...register('payment_method', {
                    required: dictionary().REQUIRED,
                  })}
                >
                  <option key={1} value='INTERNAL_TRANSFER_FOR_THE_SAME_COMPANY'>
                    {dictionary().INTERNAL_TRANSFER_FOR_THE_SAME_COMPANY}
                  </option>
                  <option key={2} value='EXCEPTION_DEPOSIT'>
                    {dictionary().EXCEPTION_DEPOSIT}
                  </option>
                  <option key={2} value='EXCEPTION_REFUND'>
                    {dictionary().EXCEPTION_REFUND}
                  </option>
                  <option key={2} value='DEPOSIT_DUE_TO_TECHNICAL_ISSUE'>
                    {dictionary().DEPOSIT_DUE_TO_TECHNICAL_ISSUE}
                  </option>
                </Select>
              </InputGroup>
              <FormErrorMessage>{errors?.payment_method?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().NAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.collector_name}
            >
              <FormLabel>{dictionary().NAME}</FormLabel>

              <InputGroup>
                <Input
                  type='text'
                  {...register('collector_name', {
                    required: false,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.collector_name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().REF_NUMBER}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors?.ref}
            >
              <FormLabel>{dictionary().REF_NUMBER}</FormLabel>

              <InputGroup>
                <Input
                  type='text'
                  {...register('ref', {
                    required: false,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.ref?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().COMMENT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} isInvalid={!!errors.note}>
              <FormLabel>{dictionary().COMMENT}</FormLabel>
              <Textarea
                size='sm'
                height='150px'
                fontSize='md'
                p='0'
                {...register('note', {
                  required: false,
                })}
              />
              <FormErrorMessage>{errors.note?.message}</FormErrorMessage>
            </FormControl>
            <ConfirmationModel
              trigger={trigger}
              addPayments={addPayments}
              getValues={getValues}
              isLoading={isLoading}
              data={[entityData?.data]}
              isDataFetched={[entityData?.isFetched]}
              width={['100%', '85%', '85%', '85%']}
            />
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddDeposits
