import React from 'react'
import XLSX from 'xlsx-js-style'
import dictionary from '../../dictionary'

// Define styles
const headerStyle = {
  font: { bold: true },
  alignment: { horizontal: 'center', vertical: 'center' },
  fill: { fgColor: { rgb: 'E4B1ED' } }, // Purple background color
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } },
  },
}

const cellStyle = {
  alignment: { horizontal: 'center', vertical: 'center' },
  border: {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } },
  },
}

const ExportTaxIdData = (data: any, fileName: string) => {
  // Define the headers
  const headers = [dictionary().ID_NUMBER, dictionary().TAX_ID_NUMBER, dictionary().CUSTOMER, dictionary().CORPORATE_ID, dictionary().CORPORATE_NAME]

  // Start with the headers row
  const ws_data = [headers]

  // Loop through corporates and dynamically populate rows
  data?.corporates.forEach((corporate: any, index: number) => {
    const row: any[] = [
      index === 0 ? data?.id : '', // ID only for the first row
      index === 0 ? data?.tax_id_number : '', // Tax ID Number only for the first row
      corporate.customer.name, // Customer Name
      corporate.id, // Corporate ID
      corporate.name, // Corporate Name
    ]
    ws_data.push(row)
  })

  // Create a worksheet
  const ws = XLSX.utils.aoa_to_sheet(ws_data)

  // Apply header style
  ws_data[0].forEach((_, colIdx) => {
    const cellRef = XLSX.utils.encode_cell({ r: 0, c: colIdx }) // First row is the header
    ws[cellRef].s = headerStyle // Apply header style
  })

  // Apply cell style to data rows
  for (let row = 1; row < ws_data.length; row++) {
    ws_data[row].forEach((_, colIdx) => {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: colIdx }) // Data rows
      ws[cellRef].s = cellStyle // Apply cell style
    })
  }

  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, fileName)

  // Export the Excel file
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export default ExportTaxIdData
