import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { HStack, Th } from '@chakra-ui/react'
import { FC } from 'react'
import dictionary from '../../dictionary'
import { useFilterStore } from './../../stores/filterStore'

const RowId: FC = () => {
  const { sort_direction, setSortDirection } = useFilterStore()
  return (
    <Th position='sticky' top='0' bg='gray.50' zIndex='9' style={{ textAlign: 'center', minWidth: 'max-content', width: 'max-content' }}>
      <HStack style={{ cursor: 'pointer' }}>
        <p style={{ fontWeight: 600 }}>{dictionary().TRANSACTION_ID}</p>
        <HStack
          onClick={() => {
            setSortDirection(sort_direction === 'ASC' ? 'DESC' : 'ASC')
          }}
        >
          {sort_direction === 'ASC' ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </HStack>
      </HStack>
    </Th>
  )
}

export default RowId
