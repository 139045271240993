import { Redirect, Route, Switch } from 'react-router-dom'
import CorporateGroups from '../../pages/corporate-groups/CorporateGroups'
import CorporateGroupPage from '../../pages/corporate-groups/one'
import AddCorporateDeposits from '../../pages/corporates/AddDeposits'
import AddWithdraw from '../../pages/corporates/AddWithdraw'
import Corporates from '../../pages/corporates/corporates'
import Invoices from '../../pages/corporates/invoices'
import CorporatePage from '../../pages/corporates/one'
import { ChangePassword } from '../../pages/ChangePassword/ChangePassword'
import TaxIds from '../../pages/tax-ids/TaxIds'
import TaxIdDetails from '../../pages/tax-ids/TaxIdDetails'
import { useFeatureFlags } from '../../amplitude/useFeatureFlags'
import { FEATURE_FLAGS } from '../../amplitude/FEATURE_FLAGS'

export const ACCOUNT_MANAGER_ROUTES = (): JSX.Element => {
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const isTaxIdFlagEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.DEV_TAX_ID_MANAGEMENT)
  return (
    <Switch>
      <Route exact path='/corporates' render={() => <Corporates />} />
      <Route exact path='/corporates/:id' render={() => <CorporatePage />} />
      <Route exact path='/corporates/:id/deposits' render={() => <AddCorporateDeposits />} />
      <Route exact path='/corporates/:id/withdraw' render={() => <AddWithdraw />} />
      <Route exact path='/corporates/:id/invoices' render={() => <Invoices />} />
      <Route exact path='/corporate-groups' render={() => <CorporateGroups />} />
      <Route exact path='/corporate-groups/:id' render={() => <CorporateGroupPage />} />
      <Route exact path='/change-password' render={() => <ChangePassword />} />

      {isTaxIdFlagEnabled && <Route exact path='/tax-ids' render={() => <TaxIds />} />}
      {isTaxIdFlagEnabled && <Route exact path='/tax-ids/:id' render={() => <TaxIdDetails />} />}

      <Redirect exact from='/' to='/corporates' />
      <Redirect exact from='/admin/login' to='/corporates' />
      <Redirect exact from='/*' to='/corporates' />
    </Switch>
  )
}
