import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { Container } from 'rsuite'

import { pathOr } from 'ramda'
import Card from '../../components/core/Card'
import Header from '../../components/core/Header'
import Table from '../../components/core/Table'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import ExportTaxIdData from './ExportTaxIdData'
import TaxIdMainInfo from './TaxIdMainInfo'

const ChargingProfilePage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const [taxIdData, setTaxIdData] = useState<any>([])
  const taxIdHeaders = [
    {
      id: 'id',
      key: 'id',
      name: dictionary().ID_NUMBER,
      type: 'text',
    },
    {
      id: 'tax_id_number',
      key: 'tax_id_number',
      name: dictionary().TAX_ID_NUMBER,
      type: 'text',
    },
    {
      id: 'tax_ids_corporates',
      key: 'tax_ids_corporates',
      name: dictionary().CUSTOMER,
      type: 'text',
    },
  ]
  const taxIdCorporatesHeaders = [
    {
      id: 'id',
      key: 'id',
      name: dictionary().ID,
      type: 'text',
    },
    {
      id: 'name',
      key: 'name',
      name: dictionary().CORPORATE_NAME,
      type: 'text',
    },
  ]

  const { isLoading, mutate } = useMutation(() => fetch('GET', `/tax-id/${id}`), {
    onSuccess: (data): void => {
      setTaxIdData(data)
    },
  })

  useEffect(() => {
    mutate()
  }, [])

  return (
    <Container>
      <Box style={{ minHeight: '100vh' }}>
        <Box px={[5, 8]} pt={[5, 8]}>
          <Header
            isLoading={isLoading}
            title={dictionary().CHARGING_PROFILE_DETAILS + ' :'}
            action={
              <Button colorScheme='blue' sx={{ height: '40px' }} onClick={() => ExportTaxIdData(taxIdData, `تفاصيل الضريبة  - ${id}`)}>
                <img src='/assets/download.svg' style={{ width: '20px', height: '20px', margin: '0px 5px' }} />
                تحميل
              </Button>
            }
          />
        </Box>
        <Flex flexDir='column' gap={10} px={8}>
          <TaxIdMainInfo taxIdData={taxIdData} taxIdHeaders={taxIdHeaders} isLoading={isLoading} />
          <Card h='100%' p={3}>
            <Text m={3} fontSize='20px' fontWeight={700} p={[5, 2, 2, 2]}>
              {dictionary().CORPORATES}
            </Text>
            <hr />
            <Box p={3} mt={3}>
              <Table
                raised
                enableIdRedirect={false}
                handleIsBalancesChange={mutate}
                height={390}
                headers={taxIdCorporatesHeaders}
                data={pathOr([], ['data'], { data: taxIdData?.corporates })}
              />
            </Box>
          </Card>
        </Flex>
      </Box>
    </Container>
  )
}

export default ChargingProfilePage
