import { SearchIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { parse, stringify } from 'query-string'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import dictionary from '../../dictionary'
import useDebounce from '../../helpers/useDebounce'

export const SearchByServiceID: React.FC = () => {
  const { push } = useHistory()
  const location = useLocation()
  const query = parse(location.search)
  const [searchTerm, setSearchTerm] = useState(query.searchTerm as string)

  const search = useDebounce((value: string) => {
    value ? (query.service_transaction_id = value) : delete query.service_transaction_id
    delete query.page
    push({ search: stringify(query) })
  }, 900)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
    search(e.target.value)
  }

  return (
    <InputGroup>
      <InputLeftElement height='100%' pointerEvents='none'>
        <SearchIcon color='gray.700' />
      </InputLeftElement>
      <Input
        placeholder={dictionary().SEARCH_BY_TRANSACTION_ID}
        onChange={onChange}
        value={searchTerm ?? ''}
        type='text'
        size='lg'
        focusBorderColor='blue.500'
        onInput={(e: any) => {
          e.target.value = e.target.value.replace(/\D/g, '') // Removes any non-digit characters
        }}
      />
    </InputGroup>
  )
}
