// useFeatureFlags.ts
import { useEffect } from 'react'
import { FEATURE_FLAGS } from './FEATURE_FLAGS'
import { useInitAmplitudeExperiment } from './useInitAmplitudeExperiment'

export const useFeatureFlags = () => {
  const client = useInitAmplitudeExperiment()

  const isFeatureFlagEnabled = (featureFlagKey: FEATURE_FLAGS) => {
    if (client) {
      try {
        const variants = client.variant(featureFlagKey)
        const variantValue = variants.value
        return variantValue === 'on' ? true : false
      } catch (error) {
        console.error('Error fetching feature flag variant:', error)
      }
    }
  }

  useEffect(() => {
    if (!client) {
    }
  }, [client])

  return {
    isFeatureFlagEnabled,
  }
}
