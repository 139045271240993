import OctaneHeader from '../components/core/OctaneTable/Header'
import RowId from '../components/FuelTransactionTable/RowId'
import dictionary from '../dictionary'
import { flatObject } from '../helpers/flatObject'

const carServicesHeaders: { [key: string]: React.ReactNode } = {
  id: <RowId />,
  created_date: <OctaneHeader value={dictionary().DATE} />,
  created_time: <OctaneHeader value={dictionary().TIME} />,
  service_type: <OctaneHeader value={dictionary().SERVICE_TYPE} />,
  status: <OctaneHeader value={dictionary().STATUS} />,
  corporate_name: <OctaneHeader value={dictionary().CORPORATES_NAME} />,
  station_name: <OctaneHeader value={dictionary().STATION_NAME} />,
  driver_name: <OctaneHeader value={dictionary().DRIVER_NAME} />,
  driver_code: <OctaneHeader value={dictionary().DRIVER_CODE} />,
  vehicle_id: <OctaneHeader value={dictionary().VEHICLE_ID} />,
  vehicle_code: <OctaneHeader value={dictionary().VEHICLE_CODE} />,
  vehicle_number_plate: <OctaneHeader value={dictionary().NUMBER_PLATE} />,
  pos_serial_number: <OctaneHeader value={dictionary().POS_SERIAL_NUMBER} />,
  service_amount: <OctaneHeader value={dictionary().AMOUNT} />,
  fees: <OctaneHeader value={dictionary().AMOUNT_FEES} />,
  total_amount: <OctaneHeader value={dictionary().AMOUNT_TOTAL} />,
  images: <OctaneHeader value={dictionary().IMAGES} />,
}

export const getCarSevicesHeaders = (): { headersList: any; headers: any } => {
  return { headersList: flatObject(carServicesHeaders, 'keys'), headers: flatObject(carServicesHeaders, 'values') }
}
