import { Flex, Text } from '@chakra-ui/react'
import moment from 'moment'
import { getCarSevicesHeaders } from '../../constants/carServicesHeaders'
import dictionary from '../../dictionary'
import CarServicesModal from '../core/CarServicesModal'
import { OctaneTableBuilder } from '../core/OctaneTable/OctaneTableBuilder'

type CarServicesTableProps = {
  data: any
  loading: boolean
}

const CarServicesTable = ({ data, loading }: CarServicesTableProps): React.ReactNode => {
  const CAR_SERVICES_HEADERS = getCarSevicesHeaders()
  const tableBuilder = new OctaneTableBuilder(data, CAR_SERVICES_HEADERS)
    .addSpecialFields('id', (row: any) => {
      return <p style={{ fontWeight: 600 }}>{row?.id}</p>
    })
    .addSpecialFields('created_date', (row: any) => {
      return <p>{moment.utc(new Date(row?.created_at as any)).format('DD/MM/YYYY')}</p>
    })
    .addSpecialFields('created_time', (row: any) => {
      return <p>{moment.utc(row?.created_at as any).format('hh:mm A')}</p>
    })
    .addSpecialFields('corporate_name', (row: any) => {
      return <p>{row?.corporate?.name}</p>
    })
    .addSpecialFields('service_type', (row: any) => {
      return <p>{['OIL_CHANGE', 'CAR_WASH'].includes(row?.service_type) ? dictionary()[row?.service_type as keyof typeof dictionary] : '-'}</p>
    })
    .addSpecialFields('vehicle_id', (row: any) => {
      return <p>{row?.vehicle?.id}</p>
    })
    .addSpecialFields('vehicle_number_plate', (row: any) => {
      return <p>{row?.vehicle?.number_plate}</p>
    })
    .addSpecialFields('vehicle_code', (row: any) => {
      return <p>{row?.vehicle?.code}</p>
    })
    .addSpecialFields('driver_code', (row: any) => {
      return <p>{row?.driver?.code}</p>
    })
    .addSpecialFields('driver_name', (row: any) => {
      return <p>{row?.driver?.name}</p>
    })
    .addSpecialFields('status', (row: any) => {
      return <p>{dictionary()[row?.status as keyof typeof dictionary]}</p>
    })
    .addSpecialFields('station_name', (row: any) => {
      return <p>{row?.station?.name?.ar}</p>
    })
    .addSpecialFields('pos_serial_number', (row: any) => {
      return <p>{row?.pos?.serial_number}</p>
    })
    .addSpecialFields('images', (row: any) => {
      return <CarServicesModal data={row} />
    })

  const carServicesTable = tableBuilder.build()

  return data && !loading && data?.data?.length !== 0 ? (
    carServicesTable
  ) : !loading && data && data?.data?.length == 0 ? (
    <Flex w='100%' h='100%' align='center' justify='center'>
      <Text fontSize='2xl'>
        {dictionary().NO_RESULTS_FOUND} {dictionary().IN} {dictionary().CAR_SERVICES_TRANSACTION}
      </Text>
    </Flex>
  ) : (
    <Flex w='100%' h='100%' align='center' justify='center'>
      <Text fontSize='2xl'>
        {dictionary().SEARCH_LOADING} {dictionary().IN} {dictionary().CAR_SERVICES_TRANSACTION}
      </Text>
    </Flex>
  )
}

export default CarServicesTable
