import { Box, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, Select, Textarea } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import Reqiured from '../reqiured'
import PaymentInfo from './PaymentInfo'

interface Prpos {
  register: any
  errors: any
  watch: any
  data: any
  isConfirmed: boolean
}

const BackOfficePaymentDataInputs: FC<Prpos> = ({ register, errors, watch, data, isConfirmed }) => {
  const actualAmount = watch('amount')
  const [banks, setBanks] = useState([])
  const [depositTypes, setDepositTypes] = useState([])
  const depositType = watch('deposit_type')
  const PaymentCashType = ['CASH', 'CHEQUE']

  const { mutate: getBanks } = useMutation(() => fetch('GET', `/banks`), {
    onSuccess: (data): void => {
      setBanks(data)
    },
  })
  const { mutate: getDepositType } = useMutation(() => fetch('GET', `/deposit-types`), {
    onSuccess: (data): void => {
      setDepositTypes(data)
    },
  })

  useEffect(() => {
    getBanks()
    getDepositType()
  }, [])

  return (
    <>
      <PaymentInfo data={data} isConfirmed={isConfirmed} />
      {isConfirmed && (
        <>
          <Box display={'flex'} gap={12} dir={'rtl'} mt={5}>
            <span style={{ color: '#101828', fontSize: '16px', lineHeight: '20px' }}>
              {dictionary().DEPOSIT_TYPE} <Reqiured />{' '}
            </span>
            <FormControl id={'deposit_type'} w='40%' display='inline-block' isInvalid={!!errors.deposit_type}>
              <Select
                dir='ltr'
                textAlign='right'
                placeholder={dictionary().PICK}
                {...register('deposit_type', {
                  required: dictionary().REQUIRED,
                })}
              >
                {depositTypes.map((depositType: any) => (
                  <option value={depositType} key={depositType}>
                    {depositType}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.deposit_type?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          {!PaymentCashType.includes(depositType) && (
            <Box display={'flex'} gap={7} dir={'rtl'} mt={5}>
              <span style={{ color: '#101828', fontSize: '16px', lineHeight: '20px' }}>
                {dictionary().BANK_NAME} <Reqiured />{' '}
              </span>
              <FormControl id={'bank_name'} w='40%' display='inline-block' isInvalid={!!errors.bank_name}>
                <Select
                  dir='ltr'
                  textAlign='right'
                  placeholder={dictionary().PICK}
                  {...register('bank_name', {
                    required: dictionary().REQUIRED,
                  })}
                >
                  {banks.map((bank: any) => (
                    <option value={bank} key={bank}>
                      {bank}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.bank_name?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          )}
          <Box display={'flex'} gap={7} dir={'rtl'} mt={5}>
            <span style={{ color: '#101828', fontSize: '16px', lineHeight: '20px' }}>
              {dictionary().ACTUAL_AMOUNT} <Reqiured />{' '}
            </span>
            <FormControl id={'amount'} w='40%' display='inline-block' isInvalid={!!errors.amount}>
              <InputGroup size='md'>
                <Input
                  data-test={dictionary().AMOUNT}
                  pr='1rem'
                  {...register('amount', {
                    required: dictionary().REQUIRED,
                    min: {
                      value: 0,
                      message: dictionary().NO_NEGATIVE_NUMBER,
                    },
                    pattern: {
                      value: /^\d+(\.\d{0,2})?$/,
                      message: dictionary().ENTER_VALID_AMOUNT_UP_TO_2_DECIMAL,
                    },
                  })}
                  type={'text'}
                />
              </InputGroup>
              <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box display={'flex'} gap={10} dir={'rtl'} mt={5}>
            <span style={{ color: '#101828', fontSize: '16px', lineHeight: '20px' }}>
              {dictionary().DEPOSIT_DATE} <Reqiured />{' '}
            </span>
            <FormControl id={'date'} w='40%' display='inline-block' isInvalid={!!errors.date}>
              <InputGroup size='md'>
                <Input
                  pr='1rem'
                  {...register('date', {
                    required: dictionary().REQUIRED,
                    min: {
                      value: 0,
                      message: dictionary().NO_NEGATIVE_NUMBER,
                    },
                  })}
                  type={'date'}
                />
              </InputGroup>
              <FormErrorMessage>{errors.date?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          {actualAmount && +actualAmount !== +data.amount && (
            <FormControl id='reason' mt={5} w='100%' dir='rtl' isInvalid={!!errors.reason}>
              <FormLabel>
                {dictionary().REASON_ACTUAL_AMOUNT_IS_DIFFERENT_FROM_ENTERED} <Reqiured />{' '}
              </FormLabel>
              <InputGroup size='md'>
                <Textarea
                  data-test={dictionary().REASON}
                  row={3}
                  {...register('reason', {
                    required: dictionary().REQUIRED,
                  })}
                  type={'text'}
                  dir={'rtl'}
                />
              </InputGroup>

              <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
            </FormControl>
          )}
          <FormControl id='comment' mt={5} w='100%' dir='rtl' isInvalid={!!errors.comment}>
            <FormLabel>{dictionary().COMMENT}</FormLabel>
            <InputGroup size='md'>
              <Textarea
                row={3}
                {...register('comment', {
                  required: false,
                })}
                type={'text'}
                dir={'rtl'}
              />
            </InputGroup>
            <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
          </FormControl>
        </>
      )}
    </>
  )
}

export default BackOfficePaymentDataInputs
