import React, { useRef, useEffect, useState, useCallback } from 'react'
import {
  Box,
  Input,
  Wrap,
  Tag,
  TagLabel,
  TagCloseButton,
  useControllableState,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Popover,
  useDisclosure,
  useOutsideClick,
  List,
  ListItem,
  ListIcon,
  BoxProps,
  Spinner,
  Flex,
  Text,
} from '@chakra-ui/react'
import { FiCheckCircle } from 'react-icons/fi'
import { useMutation } from 'react-query'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import useDebounce from '../../helpers/useDebounce'

interface Props {
  multiple?: boolean
  singleRoute: string
  searchRoute: string
  onChange?: () => void
  value?: string[]
  displayFormatter?: (obj: any) => string
  valueFormatter?: (obj: any) => string
  isInvalid?: boolean
  setIsClosed?: (value: boolean) => void
  boxProps?: BoxProps
  displayNamePathFormatter?: (obj: any) => any
  id?: number
  pageSize?: number
  // eslint-disable-next-line @typescript-eslint/ban-types
  query?: {}
  inputType?: string
  dataTest?: string
  minInputLengthToSearch?: number
  searchKey?: string
  maxItems?: number // New prop to limit the maximum number of selected items
}

const AutoCompleteInput: React.FC<Props> = ({
  value,
  onChange,
  displayFormatter,
  valueFormatter,
  singleRoute,
  searchRoute,
  multiple,
  isInvalid,
  setIsClosed,
  boxProps,
  displayNamePathFormatter,
  id,
  dataTest,
  pageSize = 50,
  inputType = 'text',
  query = {},
  minInputLengthToSearch = 0,
  searchKey = 'searchTerm',
  maxItems, // Destructure the new prop
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)
  const wrapRef = useRef<any>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [internalValue, setInternalValue] = useControllableState({ value, onChange })
  const { isOpen, onClose, onOpen } = useDisclosure()
  useOutsideClick({ ref: popoverRef, handler: onClose })
  const [names, setNames] = useState<string[]>([])
  const [options, setOptions] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [inputValue, setInputValue] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [TotalOptions, setTotalOptions] = useState(0)

  const valuesSet = new Set(internalValue ?? [])

  const {
    mutate: callSearch,
    data: searchResult,
    isLoading: isSearching,
  } = useMutation((searchTerm: string) => fetch('GET', searchRoute, { query: { [searchKey]: searchTerm, page: page, page_size: pageSize, ...query } }))
  const defaultDisplayFormatter = useCallback((option) => option?.name?.en ?? option?.name, [])
  const defaultValueFormatter = useCallback((option) => option?.id, [])
  const actualDisplayFormatter = displayFormatter ?? defaultDisplayFormatter
  const actualValueFormatter = valueFormatter ?? defaultValueFormatter

  useEffect(() => {
    setPage(1)
  }, [])

  useEffect(() => {
    setIsClosed && setIsClosed(!isOpen)
  }, [isOpen])

  useEffect(() => {
    if (searchResult?.total) {
      setTotalOptions(searchResult?.total)
    }
  }, [searchResult?.total])

  const loadMoreOptions = async () => {
    const hasMore = TotalOptions >= page * pageSize
    if (!isDataLoading && hasMore) {
      setIsDataLoading(true)
      const nextPage = page + 1
      const newData = await fetch('GET', searchRoute, {
        query: { [searchKey]: inputRef.current?.value as string, page: nextPage, page_size: pageSize, ...query },
      })

      if (newData) {
        setOptions((prevOptions) => [...prevOptions, ...newData.data])
        setPage(nextPage)
      }

      setIsDataLoading(false)
    }
  }

  const handleScroll = () => {
    const popoverBodyElement = popoverRef.current
    const scrollPosition = popoverBodyElement?.scrollTop
    const totalScrollableHeight = popoverBodyElement ? popoverBodyElement?.scrollHeight - popoverBodyElement?.clientHeight : 0

    if (scrollPosition === totalScrollableHeight) {
      loadMoreOptions()
    }
  }

  useEffect(() => {
    const popoverBodyElement = popoverRef.current
    popoverBodyElement?.addEventListener('scroll', handleScroll)

    return () => popoverBodyElement?.removeEventListener('scroll', handleScroll)
  }, [isDataLoading, TotalOptions])

  useEffect(() => {
    // internalValue is populated after the value change but no display names created yet
    if (internalValue?.length && !names.length) {
      getDisplayNames(internalValue)
    }
  }, [value])

  useEffect(() => {
    let data = []
    if (searchResult) {
      id ? (data = searchResult?.data.filter((item: any) => item.id !== id)) : (data = [...searchResult?.data])
      setOptions(data)
    }
  }, [searchResult])

  const getDisplayNames = useCallback(
    async (newValues) => {
      setIsDataLoading(true)
      const newDisplays: string[] = []
      for (const value of newValues) {
        const data = await fetch('GET', singleRoute + `/${value}`, { query })
        if (data) {
          newDisplays.push(actualDisplayFormatter(displayNamePathFormatter ? displayNamePathFormatter(data) : data))
        }
      }
      setIsDataLoading(false)
      setNames(newDisplays)
    },
    [actualDisplayFormatter],
  )

  const deleteItem = useCallback(
    (index: number) => {
      setInternalValue(internalValue.filter((item, i) => i !== index))
      setNames(names.filter((item, i) => i !== index))
    },
    [internalValue, names, setInternalValue, setNames],
  )

  const addNew = useCallback(
    (obj) => {
      const newValue = actualValueFormatter(obj)
      const newName = actualDisplayFormatter(obj)

      // Check if the maximum number of items has been reached
      if (maxItems && internalValue?.length >= maxItems) {
        return
      }

      if (internalValue?.find((v) => v === newValue)) return

      if (multiple) {
        setInternalValue([...(internalValue ?? []), newValue])
        setNames([...names, newName])
      } else {
        setInternalValue([newValue])
        setNames([newName])
      }

      if (inputRef.current) inputRef.current.value = ''
      containerRef.current && containerRef.current.scroll({ top: containerRef.current.scrollHeight, behavior: 'smooth' })
    },
    [actualValueFormatter, actualDisplayFormatter, multiple, setInternalValue, setNames, names, internalValue, maxItems], // Include maxItems in the dependency array
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!isOpen) onOpen()
      if (e.key === 'Enter' && options.length) {
        addNew(options[0])
        if (!multiple) onClose()
      }
    },
    [addNew, options],
  )

  const search = useDebounce(
    useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (typeof e.target.value !== 'string') return
        callSearch(e.target.value)
        setPage(1)
      },
      [callSearch],
    ),
    492,
  )

  const isDisabled = maxItems && internalValue?.length >= maxItems

  return (
    <Box
      ref={containerRef}
      border={isInvalid ? '2px' : '1px'}
      borderColor={isInvalid ? 'error.500' : 'gray.200'}
      w='100%'
      minH='2em'
      borderRadius='6'
      sx={{ ':focus-within': { borderColor: 'transparent', outline: 'auto', outlineColor: 'blue.500' } }}
      p='2'
      {...boxProps}
      style={{ ...boxProps?.style, overflow: names.length > 0 ? 'scroll' : 'hidden' }}
    >
      <Popover initialFocusRef={inputRef} onClose={onClose} isOpen={isOpen && !isDisabled}>
        <PopoverTrigger>
          <Wrap
            onClick={() => {
              if (!isDisabled) {
                containerRef.current && containerRef.current.scroll({ top: containerRef.current.scrollHeight, behavior: 'smooth' })
                onOpen()
              }
            }}
          >
            {names.map((name, index) => (
              <Tag size='md' key={name} variant='solid' backgroundColor={'#7165e3'}>
                <TagLabel>{name}</TagLabel>
                <TagCloseButton onClick={() => deleteItem(index)} />
              </Tag>
            ))}

            <Input
              ref={inputRef}
              flex='1'
              minW='50px'
              variant='unstyled'
              type={inputType}
              data-test={dataTest}
              onChange={(e) => {
                if (e?.target?.value.length >= minInputLengthToSearch && !isDisabled) {
                  search(e as any)
                }
                setInputValue(e?.target?.value)
              }}
              onFocus={(e) => {
                if (e?.target?.value.length >= minInputLengthToSearch && !isDisabled) {
                  search(e as any)
                }
              }}
              onKeyDown={onKeyDown}
              onClick={() => !isDisabled && onOpen()}
              placeholder={dictionary().SEARCH}
              isDisabled={isDisabled as any} // Disable the input if max items reached
            />
            {isDataLoading && <Spinner size='md' color='blue.500' />}
          </Wrap>
        </PopoverTrigger>
        {(inputValue?.length >= minInputLengthToSearch || minInputLengthToSearch === 0) && (
          <PopoverContent w={containerRef?.current?.clientWidth}>
            <PopoverBody ref={popoverRef} h='200px' overflowY='auto' p={0} data-test='auto-complete-options'>
              {isSearching && (
                <Flex w='100%' h='200px' alignItems='center' justifyContent='center'>
                  <Spinner size='lg' color='blue.500' />
                </Flex>
              )}
              {!options.length && !isSearching && (
                <Flex w='100%' h='200px' alignItems='center' justifyContent='center'>
                  <Text>{dictionary().NO_RESULTS_FOUND}</Text>
                </Flex>
              )}
              {!!options.length && (
                <List spacing={3} p='0' ref={wrapRef}>
                  {options.map((o) => (
                    <ListItem
                      id='auto-complete-li'
                      key={actualValueFormatter(o)}
                      onClick={() => {
                        if (!isDisabled) {
                          addNew(o)
                          if (!multiple) onClose()
                        }
                      }}
                      px='2'
                      mt='0px !important'
                      h='40px'
                      sx={{
                        ':hover': { bg: !isDisabled && '#B8B2F1' },
                      }}
                      display='flex'
                      alignItems='center'
                      cursor={isDisabled ? 'not-allowed' : 'pointer'}
                      color={valuesSet.has(actualValueFormatter(o)) ? 'white' : 'black'}
                      bgColor={valuesSet.has(actualValueFormatter(o)) ? '#7165e3' : undefined}
                    >
                      {valuesSet.has(actualValueFormatter(o)) && <ListIcon as={FiCheckCircle} color='blue.500' />}
                      {actualDisplayFormatter(o)}
                    </ListItem>
                  ))}
                </List>
              )}
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  )
}

export default AutoCompleteInput
