import { Container, Flex, Grid, GridItem } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import CarServicesTable from '../../components/CarServicesTable/CarServicesTable'
import AdvancedFilter from '../../components/core/advanced-filters/AdvancedFilter'
import Header from '../../components/core/Header'
import Pagination from '../../components/core/Pagination'
import SearchDate from '../../components/core/SearchDate'
import ViewTags from '../../components/core/ViewTags'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import useQueryParams from '../../hooks/useQueryParams '
import { useFilterStore } from '../../stores/filterStore'
import { SearchByServiceID } from './SearchByServiceID'

const CarServices: React.FC = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const tags = [
    { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
    { name: dictionary().CONFIRMED, filter: [{ name: 'service_status', value: 'CONFIRMED' }] },
    { name: dictionary().PENDING, filter: [{ name: 'service_status', value: 'PENDING' }] },
  ]
  const service_transaction_id = searchParams.get('service_transaction_id')
  const { query } = useQueryParams()

  const state = useFilterStore()
  const { from, page, sort_column, sort_direction, to } = state

  const { onError } = useAlert()

  const toQueryString = (filters: any) =>
    Object.keys(filters)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key] || ''))
      .join('&')

  const { isLoading, mutate, data } = useMutation(
    (filters: any) => {
      const queryString = toQueryString(filters)
      return fetch('GET', `/service-transaction?${queryString}`)
    },
    {
      onSuccess: (): void => {},
      onError,
    },
  )

  useEffect(() => {
    const fromDate = format(new Date(from), 'yyyy-MM-dd')
    const toDate = format(new Date(to), 'yyyy-MM-dd')
    const filterObj: any = { sort_direction, page, from: fromDate, to: toDate, ...query }
    service_transaction_id ? (filterObj.service_transaction_id = service_transaction_id) : delete filterObj.service_transaction_id
    mutate(filterObj)
  }, [from, to, service_transaction_id, sort_column, sort_direction, page, query])

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={5}>
      <Flex flexDir='column' sx={{ '& > *:not(:last-child)': { marginBottom: '15px' } }}>
        <Header title={dictionary().CAR_SERVICES_TRANSACTION} to={''} />
        <Grid templateColumns='repeat(12, 1fr)' alignItems={'center'}>
          <GridItem colSpan={[12, 2, 2, 1]} display='flex' alignItems='center' mt={8} ml={8} w='100%'>
            <ViewTags tags={tags} />
          </GridItem>
          <GridItem colSpan={[12, 2, 2, 1]}>
            <SearchDate />
          </GridItem>
          <GridItem colSpan={[12, 3]} marginTop={4} marginRight={4}>
            <SearchByServiceID />
          </GridItem>
          <GridItem colSpan={[12, 3]} marginTop={4} marginRight={4}>
            <AdvancedFilter />
          </GridItem>
        </Grid>
        <GridItem colSpan={[12, 12, 5, 5]} display='flex' alignItems='center' justifyContent='flex-end'>
          <Pagination totalCount={data?.total || 0} pageSize={data?.page_size || 30} />
        </GridItem>
        {CarServicesTable({ data, loading: isLoading })}
      </Flex>
    </Container>
  )
}

export default CarServices
