// useInitializeAmplitude.ts
import { useEffect, useState } from 'react'
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client'

const apiKey = import.meta.env.VITE_AMPLITUDE_DEPLOYMENT_ID!

export const useInitAmplitudeExperiment = () => {
  const [client, setClient] = useState<ExperimentClient | null>(null)

  const init = async () => {
    const amplitudeClient = Experiment.initialize(apiKey, {
      fetchTimeoutMillis: 5000,
      retryFetchOnFailure: true,
      fetchOnStart: true,
    })
    await amplitudeClient.start()

    setClient(amplitudeClient)
  }
  useEffect(() => {
    if (!client) {
      init()
    }
  }, [client])

  return client
}
